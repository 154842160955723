import React from 'react'
import Header from '../Header/Header'
import './Hero.css'
import hero_image from "../../assets/image-hero.png"
import hero_image_back from "../../assets/hero_image_back.png"
import NumberCounter from 'number-counter'
import Heart from "../../assets/heart.png"
import Calories from "../../assets/calories.png"
import { motion } from 'framer-motion'
const Hero = () => {

    const transition = { type: 'spring', duration: 4 }
    const mobile = window.innerWidth <= 768 ? true : false;
    return (
        <div className="hero" id='Hero'>
            <div className="blur blur-hero"></div>
            <div className='left-h'>
                <Header />
                <div className='the-best-ad'>
                    <motion.div
                        initial={{ left: mobile ? "166px" : '236px' }}
                        whileInView={{ left: '9px' }}
                        transition={transition}
                    ></motion.div>
                    <span>the best fitness club in the town</span>
                </div>

                <div className="hero-text">
                    <div>
                        <span className='stroke-text'>Shape</span>
                        <span>Your</span>
                    </div>
                    <div><span>Ideal Body</span></div>
                    <div>
                        <span> in here we will help you to shape and build your ideal body and live up your life to fullest</span>

                    </div>
                </div>
                {/* figures */}
                <div className="figures">
                    <div>
                        <span>
                            <NumberCounter end={185} start={107} delay='3' preFix="+" />
                        </span>
                        <span>EXPERT COACHES</span>
                    </div>
                    <div>
                        <span> <NumberCounter end={867} start={700} delay='3' preFix="+" /></span>
                        <span>MEMBERS JOINED</span>
                    </div>
                    <div>
                        <span> <NumberCounter end={35} start={10} delay='3' preFix="+" /></span>
                        <span> FITNESS PROGRAMS</span>
                    </div>
                </div>

                {/* hero buttons */}
                <div className="hero-buttons">
                    <buttons className="btn">Get Started</buttons>
                    <buttons className="btn">Learn More</buttons>
                </div>



            </div>

            <div className='right-h'>
                <button className='btn btn-join'>Join Now</button>

                <motion.div
                    initial={{ right: "-1rem" }}
                    whileInView={{ right: "4rem" }}
                    transition={transition}
                    className='heart-rate'>
                    <img src={Heart} alt="" />
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </motion.div>

                {/* hero images */}
                <img src={hero_image} alt="" className='hero-image' />
                <motion.img
                    initial={{ right: "12rem" }}
                    whileInView={{ right: "20rem" }}
                    transition={transition} src={hero_image_back} alt="" className='hero-image-back' ></motion.img>

                {/* calories */}
                <motion.div
                    initial={{ right: "32rem" }}
                    whileInView={{ right: "28rem" }}
                    transition={transition}
                    className="calories">
                    <img src={Calories} alt="" />
                    <div>
                        <span>Calories burned</span>
                        <span>220 kcal</span>
                    </div>
                </motion.div>
            </div>
        </div>

    );
};

export default Hero;