import React from 'react'
import './Plans.css'
import whiteTick from '../../assets/whiteTick.png'
import { plansData } from '../../data/plansData'
const Plans = () => {
    return (
        <div className='plans-container' id='Plans'>
            <div className="blur blur-plans-1"></div>
            <div className="blur blur-plans-2"></div>
            <div className='Plans-header'>
                <span className='stroke-text'>Ready to start</span>
                <span>Your journey</span>
                <span className='stroke-text'>now withus</span>
            </div>

            {/* PLAN CARDS */}

            <div className="Plans-cards">

                {plansData.map((plan, i) => (
                    <div className="card" key={i}>
                        {plan.icon}
                        <span>{plan.name}</span>
                        <span>${plan.price}</span>

                        <div className="features">

                            {plan.features.map((feature, i) => (
                                <div className="feature">
                                    <img src={whiteTick} alt="" />
                                    <span key={i}>{feature}</span>
                                </div>
                            ))}
                        </div>
                        <div>
                            <span>
                                See more benefits →
                            </span>
                        </div>
                        <button className='btn'>Join Now</button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Plans