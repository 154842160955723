import React from 'react'
import './Footer.css'
import insta_icon from '../../assets/instagram.png'
import git_icon from '../../assets/github.png'
import linkedin_icon from '../../assets/linkedin.png'
import logo from '../../assets/logo.png'

export const Footer = () => {
    return (
        <div className="Footer-container">
            <hr />
            <div className="footer">
                <div className="blur blur-footer-1"></div>
                <div className="blur blur-footer-2"></div>
                <div className="social_media">
                    <a href="https://www.github.com/"><img src={git_icon} alt="" /></a>
                    <a href="https://www.instagram.com"><img src={insta_icon} alt="" /></a>
                    <a href="https://www.linkedin.com"><img src={linkedin_icon} alt="" /></a>
                </div>
                <div className='logo_section'>
                    <a href="http://localhost:3000/"><img src={logo} alt="" /></a>
                </div>
            </div>
        </div>

    )
}

export default Footer
