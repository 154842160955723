import React from 'react'
import './Testimonials.css'
import { testimonialsData } from "../../data/testimonialsData"
import right from '../../assets/rightArrow.png'
import left from '../../assets/leftArrow.png'
import { useState } from 'react';
import { motion } from 'framer-motion'
const Testimonials = () => {

    const [selected, setSelected] = useState(0);
    const tLength = testimonialsData.length;
    const transition = { type: 'spring', duration: 4 }
    return (
        <div className='Testimonials' id='testimonials'>
            <div className='left-t'>
                <div className="testimonials-header">
                    <span>Testimonials</span>
                </div>
                <div className="testimonials">
                    <span className='stroke-text'>what they</span>
                    <span>say about us</span>
                    <motion.span
                        key={selected}
                        initial={{ opacity: 0, x: -100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ ...transition, duration: 1 }}
                    >
                        {testimonialsData[0].review}
                    </motion.span>
                    <span>
                        <span style={{ color: 'var(--orange)' }}>{testimonialsData[selected].name}</span>{" "}
                        - {testimonialsData[selected].status}
                    </span>
                </div>
            </div>
            <div className='right-t'>
                <motion.div
                    initial={{ opacity: 0, x: -100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ ...transition, duration: 2 }}
                ></motion.div>
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ ...transition, duration: 2 }}
                ></motion.div>
                <motion.img
                    key={selected}
                    initial={{ opacity: 0.3, x: 80 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ ...transition, duration: 1 }}
                    src={testimonialsData[selected].image} alt="" >
                </motion.img>
                <div className='arrows'>
                    <img
                        onClick={() => {
                            selected === 0
                                ? setSelected(tLength - 1)
                                : setSelected((prev) => prev - 1);
                        }}
                        src={left}
                        alt='' />

                    <img
                        onClick={() => {
                            selected === tLength - 1
                                ? setSelected(0)
                                : setSelected((prev) => prev + 1);
                        }}
                        src={right}
                        alt='' />

                </div>
            </div>
        </div >
    )
}

export default Testimonials